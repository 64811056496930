import React from 'react';

const ConcentricCirclesChart = ({ visualData }) => {
  const colors = ['#8a2be2', '#9370db', '#9f79ee', '#ab82ff', '#b23aee', '#bf3eff', '#4b0082', '#6a5acd', '#7b68ee', '#8470ff'];
  const steps = visualData.data;
  const Label= visualData.spec.columns[0].column
  const me=visualData.spec.metrics[0].column
  const circles = steps[Label].map((label, index) => ({
    label,
    size:steps[me][index],
    color: colors[index % colors.length] // Use modulo to cycle through colors
  })).sort((a, b) => b.size - a.size);

  const maxSize = Math.max(...steps[me]);
  return (
    <div className="flex justify-center items-end bg-gray-900">
      <div className="relative w-96 h-96 mb-4">
        {circles.map((circle, index) => {
          const size = (circle.size / maxSize) * 100;
          return (
            <div
              key={circle.label}
              className="absolute rounded-full flex flex-col justify-start items-center pt-2"
              style={{
                width: `${size}%`,
                height: `${size}%`,
                bottom: '0',
                left: `${50 - size / 2}%`,
                border: `2px solid ${circle.color}`,
                backgroundColor: 'transparent',
                transition: 'all 0.3s ease-in-out',
              }}
            >
              <span
                className="text-white text-center"
                style={{
                  fontSize: `${0.8 + (circle.size / maxSize) * 0.4}rem`,
                }}
              >
                {circle.label}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};


export default ConcentricCirclesChart;


// // Example usage
// const ExampleUsage = () => {
//   const exampleData = {
//     label: ['社会', '社区', '熟人', '朋友', '家人', '自己'],
//     size: [60, 50, 40, 30, 20, 10]
//   };

//   return <ConcentricCirclesChart data={exampleData} />;
// };