import React, { memo } from 'react';
// import { Handle, Position } from 'reactflow';
import styled from 'styled-components';

const Node = styled.div`
  // width: 70px;
  // height: fit-content;
  width: ${(props) => props.width ? `${props.width}px` : 'auto'};
  height: ${(props) => props.height ? `${props.height}px` : 'auto'};
  background: ${(props) => props.background ? `${props.background}` : 'transparent'};
  border: ${(props) => props.dashed ? `dashed 1px #aaa` : 'solid 1px #aaa'};
  border-radius: 6px;
  word-wrap:  break-word;
  font-size: 12px;
`;

function CustomSubFlow(props) {
  const { data } = props;
  const { label, style } = data;
  const { width, height, color = '', dashed = false } = style;

  return (
    <Node background={color} width={width} height={height} dashed={dashed}>
      <>
        <div
          className={`text-[11px] font-semibold text-center p-2 text-gray-400 max-w-[100%] mx-auto w-full h-full -mt-7`}>
          {label}
        </div>
      </>
    </Node>
  );
}

export default memo(CustomSubFlow);