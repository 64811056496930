import React from 'react';
import Chart from '../Chart';
import { useSelector } from "react-redux"
const processDataForBarChart = (data,type) => {
  const visuals = data


  // const visuals = {
  //       "vid": "",
  //       "title": "Fortune 500 Top Industries",
  //       "databasequery": "",
  //       "interpretations": [],
  //       "spec": {
  //           "columns": [
  //               {
  //                   "techtext": "Industry",
  //                   "column": "Industry",
  //                   "dtype": "str"
  //               },
  //               {
  //                   "techtext": "CompanyCount",
  //                   "column": "CompanyCount",
  //                   "dtype": "int"
  //               }
  //           ],
  //           "dimensions": [
  //               {
  //                   "column": "Industry"
  //               }
  //           ],
  //           "metrics": [
  //               {
  //                   "column": "CompanyCount"
  //               },
  //               {
  //                 "column": "sum"
  //             }
  //           ],
  //           "category": [],
  //           "commands": [],
  //           "vtype": {
  //               "vtype": "BAR",
  //               "alternatives": [],
  //               "resistence_to_transit": 0.875
  //           },
  //           "visualaction": "",
  //           "networkspec": {
  //               "nodes": [],
  //               "edges": [],
  //               "config": {
  //                   "node_label": {},
  //                   "node_image": {}
  //               }
  //           },
  //           "cardspec": []
  //       },
  //       "data": {
  //           "Industry": [
  //               "Technology",
  //               "Healthcare",
  //               "Financial Services",
  //               "Retail",
  //               "Energy",
  //               "Automotive",
  //               "Telecommunications",
  //               "Consumer Goods",
  //               "Manufacturing",
  //               "Media"
  //           ],
  //           "CompanyCount": [
  //               12,
  //               8,
  //               7,
  //               6,
  //               5,
  //               4,
  //               4,
  //               3,
  //               3,
  //               3
  //           ],
  //           "sum": [
  //               120,
  //               87,
  //               7,
  //               6,
  //               5,
  //               4,
  //               4,
  //               3,
  //               3,
  //               3
  //           ]
  //       },
  //       "suggestions": {},
  //       "computationsetting": []
  //   }
  const { dimensions, metrics } = visuals.spec;
  const xAxisData = visuals.data[dimensions[0].column];
  let optionsData =[];
 
  // let maxMetricValue = 0;
  // metrics.forEach((item,index) => {
  //    let max= Math.max.apply(null, visuals.data[item.column]); 
  //    if(max>maxMetricValue){
  //      maxMetricValue=max
  //    }
  // });
  metrics.forEach((item,index) => {
    optionsData.push({
      tooltip: {
        trigger: "item",
      },
      grid: {
        bottom: type === 'filteredBar' ? '10%' : 0
      },
      xAxis: {
        type: 'category',
        data: xAxisData,
      },
      yAxis: {
        type: 'value',
        // max:maxMetricValue,
      },
      series: [{
        type: 'bar',
        data: visuals.data[item.column],
      }],
    }) 
  });

  return { optionsData };
};

const BarChart = ({ visualData, type,rndData,rd }) => {

  const { optionsData } = processDataForBarChart(visualData,type)
 
  return (
    <div className='flex h-full'>
     {optionsData.map((option, index) =>
      <Chart key={index} option={option} rd={rd}/>
      )}
    </div>
    );
};

export { BarChart };
