import React from "react";

import {
  BarChart,
  DonutChart,
  LineChart,
  PieChart,
  DonutGaugeChart,
  FunnelChart,
} from "../Chart/EchartsOneDimension";
import {
  StackBarChart,
  HeatMapChart,
  GroupedBarChart,
  TreeMapChart,
  TextCloudChart,
  CirlePackingChart,
  TimeLine,
  GridBarChart,
  GridLineChart,
  GridDonutChart,
  RadarChart,
  SankeyChart,
  SunburstChart,
  ScatterChart,
} from "../Chart/EchartsColumnsMore";
import { Card, Tables } from "../Chart/Cards";
import { FilteredBarChart } from "../Chart/Filtered/FilteredBar";
import { ParliamentDonutChart, CalendarChart } from "../Chart/OthersChart";
import RecordCard from "../RecordCard/RecordCard";
import WebCard from "../RecordCard/WebCard";
import Landscape from "../LandScape";
import FlowDiagram from "../FlowDiagram";
import OrgChart from "../OrgChart";
import SmartAccordion from "../Accordion";
import Flywheel from "../Flywheel/Flywheel";
import Process from "../Process/process";

import ConcentricCirclesChart from "../ConcentricCircle/ConcentricCirclesChart";

import Network from "../Network/Network";
import NetworkGraphRaw from "../Network/NetworkGraphRaw";
import Tree from "../Network/Tree";
import HexagonWall from "../Chart/HexagonWall/HexagonWall";
import FlippableCards from "../Chart/FlippableCards/FlippableCards";
import MonthCalendar from "../Chart/MonthCalendar/MonthCalendar";

import CircularNetwork from "../CircularNetwork/CircularNetwork";

import SmartMap from "../Chart/SmartMap/SmartMap";
const chartComponents = {
  BAR: BarChart,
  DONUT: DonutChart,
  LINE: LineChart,
  PIE: PieChart,
  DONUTGAUAGE: DonutGaugeChart,

  STACKBAR: StackBarChart,
  HEATMAP: HeatMapChart,
  GROUPEDBAR: GroupedBarChart,
  TREEMAP: TreeMapChart,
  TEXTCLOUD: TextCloudChart,
  CirlePacking: CirlePackingChart,
  TIMELINE: TimeLine,
  GRIDBAR: GridBarChart,
  GRIDLINE: GridLineChart,
  GRIDDONUT: GridDonutChart,
  FLATCARD: Card,
  FUNNEL: FunnelChart,
  TABLE: Tables,
  RADAR: RadarChart,
  SANKEY: SankeyChart,
  SUNBURST: SunburstChart,
  FILTEREDBAR: FilteredBarChart,
  SCATTER: ScatterChart,
  ParliamentDonut: ParliamentDonutChart,
  CALENDAR: CalendarChart,
  RECORDCARD: RecordCard,
  LANDSCAPE: Landscape,
  GRAPH: FlowDiagram,
  ORGCHART: OrgChart,
  ACCORDION: SmartAccordion,
  WebCard: WebCard,
  NETWORK_FROM_TABLE: Network,
  GRAPH_RAW: NetworkGraphRaw,
  TREE: Tree,
  FLYWHEEL:Flywheel,
  CONCENTRICCIRCLE:ConcentricCirclesChart,
  PROCESS:Process,
  CircularNetwork:CircularNetwork,
  FlippableCards:FlippableCards,
  HexagonWall:HexagonWall,
  TASKCALENDAR:MonthCalendar,
  SmartMap:SmartMap
};

const DynamicChart = (props) => {
  const { rndData, rndNo, chartType, rd } = props;
  const ChartComponent = rndData[rndNo]?.currentType
    ? chartComponents[rndData[rndNo].currentType]
    : chartComponents[chartType];

  if (ChartComponent) {
    return (
      <div
        onMouseDown={(e) => e.stopPropagation()}
        className="w-full h-full cursor-pointer"
      >
        <ChartComponent {...props} />
      </div>
    );
  }
  return null;
};

export { DynamicChart };
