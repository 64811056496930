import { useEffect, useState } from "react";
import { Rnd } from "react-rnd";

const CustomRnd = ({ children, parentRef, rndData, setRndData, no, zIndex, type, }) => {
  // const parentHeight = parentRef.current?.offsetHeight
  const isSetHeight = type?.indexOf('banner') > -1 || type==='horizontal' || rndData[no]?.chartData?.type === "GRAPH"

  const [parentWidth, setParentWidth] = useState(parentRef.current?.offsetWidth)
  const [position, setPosition] = useState({ x: rndData[no]['pos']?.x ?? 10, y: rndData[no]['pos']?.y ?? 10 }); // Use percentages
  const [size, setSize] = useState({ width: rndData[no]['size']?.width ?? 300, height: isSetHeight ? rndData[no]['size']?.height : 'auto' });
  // const [size, setSize] = useState({ width: rndData[no]['size']?.width ?? 300 });
  // , height: rndData[no]['size']?.height ?? 70 

  const handleResize = (e, direction, ref, delta, position) => {
    const newPos = {
      x: position.x,
      y: position.y
    }
    setPosition(newPos);

    const newSize = {
      width: ref.style.width,
      height: ref.style.height,
    }
    setSize(newSize);
  };

  const handleDrag = (e, d) => {
    const newPos = {
      x: d.x,
      y: d.y
    }
    setPosition(newPos);
  };

  const handleDragStart = () => {
    rndData.forEach((item, itemNo) => {
      if (itemNo === no) {
        item = { ...item, zIndex: 2 }
      } else {
        item = { ...item, zIndex: 1 }
      }
    });
    setRndData([...rndData])
  }
  const handleDragStop = () => {
    rndData[no] = { ...rndData[no], pos: position }
    setRndData([...rndData])
  }

  const handleResizeStop = () => {
    rndData[no] = { ...rndData[no], pos: position }
    rndData[no] = { ...rndData[no], size: size }
    setRndData([...rndData])
  }
  const handleDoubleClick = () => {
    // Assuming the content that needs to be resized is wrapped in a div with a class 'content-wrapper'
    const contentWrapper = document.querySelector(`.rnd-container .${'visCardHight' + no}`);
    if (contentWrapper) {
      const newHeight = contentWrapper.scrollHeight + 10;
      setSize(prevSize => ({
        ...prevSize,
        height: newHeight,
      }));
      rndData[no] = { ...rndData[no], size: { ...size, height: newHeight } };
      setRndData([...rndData]);
    }
  };
  useEffect(() => {
    const container = parentRef.current;
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        // entry.contentRect.width contains the new width of the observed element
        setParentWidth(entry.contentRect.width)
      }
    });
    // Start observing the container
    resizeObserver.observe(container);
    return () => {
      resizeObserver.disconnect();
    };
  }, [parentRef]);

  useEffect(() => {
    const data = rndData[no]
    if (data) {
      const size1 = {width: data.size.width }
      const size2= {...data.size,width: data.size.width }
      const pos = { x: data.pos.x, y: data.pos.y }
      setPosition(pos)
      setSize(type?.indexOf('banner') > -1 || type==='horizontal'? size2:size1)

    }
  }, [rndData, no])

  return (
    <Rnd
      className={`z-[${zIndex}] rnd-container h-fit`}
      size={size}
      position={position}
      onResize={handleResize}
      onResizeStop={handleResizeStop}
      onDragStop={handleDragStop}
      onDrag={handleDrag}
      resizeGrid={[4, 4]}
      dragGrid={[4, 4]}
      bounds='parent'
      onDragStart={handleDragStart}
      onDoubleClick={handleDoubleClick}
      resizeHandleClasses={{
        bottomRight: 'resize-handle',
        bottomLeft: 'resize-handle',
        topRight: 'resize-handle',
        topLeft: 'resize-handle',
        right: 'resize-handle',
        left: 'resize-handle',
        top: 'resize-handle',
        bottom: 'resize-handle',
      }}
    >
      {children}
    </Rnd>
  )
}

export default CustomRnd;