import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import styled from 'styled-components';

const Node = styled.div`
  width: ${(props) => (props.width && 1000 > props.width && props.width > 90) ? props.width : '100'}px;
  height: ${(props) => (props.height && 500 > props.height && props.height > 40) ? `${props.height}px` : 'fit-content'};
  word-wrap:  break-word;
  position: relative;
  z-index: 3;
  font-size: 12px;
  .react-flow__handle {
    background: ${(props) => props.background ? props.background : '#01C2D7'};
    width: 8px;
    height: 8px;
    border-radius: 4px;
    // border: none;
  }
`;

function CustomNode(props) {
  const { data } = props;
  const {
    label: defaultLabel, style, setDetails, fields, color,
    rndNo, config, obj_key, setStyleSettingNo, setShowDrawer,direction } = data;
  let label = defaultLabel
  let image = ''
  if (fields && config) {
    const matchImage = fields.filter((val) => val.label === config['key_img'])[0]
    const matchLabel = fields.filter((val) => val.label === config['key_label'])[0]
    if (matchImage) image = matchImage?.value
    if (matchLabel) label = matchLabel?.value
  }
  const sourcePosition = direction === 'horizontal' ? Position.Right : Position.Bottom;
  const targetPosition = direction === 'horizontal' ? Position.Left : Position.Top;
  return (
    <Node background={color} width={style.width} height={style.height}>
      <>
        <Handle type="target" position={targetPosition} id='a' />
        <Handle type="source" position={sourcePosition} id='c' />
        {/* <Handle type="target" position={Position.Left} id='d' />
          <Handle type="source" position={Position.Right} id='b' /> */}

        <div
          onClick={() => {
            setShowDrawer(true)
            setStyleSettingNo()
            setDetails({ data: fields, name: label, rndNo, obj_key: obj_key, type: 'flowchart' })
          }}
          style={{ background: color ? color : '#86efac' }}
          className={`
          ${style?.shape === 'rect' ? 'rounded-lg' : ''} 
          ${style?.shape === 'circle' ? 'rounded-full' : ''} 
          text-[9px] flex justify-center items-center cursor-pointer p-2 gap-2 text-[#4F4764] leading-[15px] max-w-[100%] mx-auto w-full h-full text-white rounded`}>
          {image && <img src={image} className='w-4 h-4 rounded' alt='' />}
          <div className='text-overflow-1'>{label}</div>
        </div>
      </>
    </Node>
  );
}

export default memo(CustomNode);