import React from 'react';

const TimelineStep = ({ number, title, color, content }) => (
  <div className="flex items-center mb-8 relative">
    <div className="w-1/4"></div>
    <div className={`w-3/4 flex items-center rounded-full`}  style={{ backgroundColor: color }}>
      <div className={`w-20 h-20 rounded-full bg-white flex items-center justify-center shadow-lg z-20 mr-4`}>
        <span className={`text-${color}-500 text-2xl font-bold`}>{number}</span>
      </div>
      <div className={`flex-1 py-2`}>
        <h3 className="text-zinc font-bold uppercase">{title}</h3>
        <p className="text-zinc-400 text-s mt-1">{content}</p>
      </div>
    </div>
    <div className="absolute left-[20%] top-1/2 w-8 h-8 rounded-full bg-gray-300 z-10 -translate-x-1/2 -translate-y-1/2"></div>
    <div className="absolute left-[20%] top-1/2 w-8 h-0.5 bg-gray-300 -translate-y-1/2"></div>
  </div>
);

const Process = ({ visualData }) => {
  // const steps = {
  //   label: ["Hello", "world", "Foo bar", "text", "monter"],
  //   description: ["Bla bla bla", "Lorem ipsum jeghioe age", "ABC DEF EHIGHIEOGNH"],
  // };
  const steps = visualData.data;
  const dimensionsLabel=visualData.spec.dimensions[0].column
  const metricsLabel= visualData.spec.columns[1].column
 
  const colors = ['#FFFF00', '#FFA500', '#FFC0CB', '#800080', '#00FFFF'];

  const title = visualData.title;

  return (
    <div className="p-8 bg-gray-100 flex">
      <div className="w-1/4 flex justify-center items-center pt-8">
        <div className="bg-white rounded-full w-44 h-44 p-6 shadow-lg relative">
          <h2 className="text-2xl font-bold text-center">5 STEPS</h2>
          <div  className="text-xl  text-center">{title}</div>
        </div>
        <div className="absolute left-[29%]  w-24 h-1 bg-gray-300 "></div>
      </div>
      <div className="w-3/4 relative">
        <div className="absolute left-[19.5%] top-0 bottom-0 w-1 bg-gray-300"></div>
        {steps[dimensionsLabel].map((label, index) => (
          <TimelineStep
            key={index}
            number={`0${index + 1}`}
            title={label}
            color={colors[index % colors.length]}
            content={steps[metricsLabel][index]}
          />
        ))}
      </div>
    </div>
  );
};

export default Process;
