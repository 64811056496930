import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

function convert_table_to_json_for_network(inputTable, layer1Column, layer2Column, colorColumn, sizeColumn) {
  // Initialize data structure for the output
  const data = {
    layers: [],
    edges: []
  };

  // Create the first layer based on the layer1Column
  const layer1 = {
    name: layer1Column,
    nodes: []
  };

  // Create the second layer based on the layer2Column
  const layer2 = {
    name: layer2Column,
    nodes: []
  };

  // Iterate through the inputTable to fill in the nodes and edges
  inputTable.forEach(row => {
    const layer1Value = row[layer1Column];
    const layer2Value = row[layer2Column];
    const groupValue = row[colorColumn];
    const countValue = row[sizeColumn];

    // Add the node for the first layer (layer1)
    let layer1Node = {
      id: `${layer1Column}.${layer1Value}`,  // Example: "l1.A"
      name: layer1Value,
      group: groupValue,     // Group from colorColumn
      count: countValue      // Count from sizeColumn
    };

    // Add node to layer1 if it doesn't already exist
    if (!layer1.nodes.some(node => node.id === layer1Node.id)) {
      layer1.nodes.push(layer1Node);
    }

    // Add the node for the second layer (layer2)
    let layer2Node = {
      id: `${layer2Column}.${layer2Value}`,  // Example: "l2.Apple"
      name: layer2Value
    };

    // Add node to layer2 if it doesn't already exist
    if (!layer2.nodes.some(node => node.id === layer2Node.id)) {
      layer2.nodes.push(layer2Node);
    }

    // Create the edge between the first and second layers
    data.edges.push({
      from: layer1Node.id,
      to: layer2Node.id
    });
  });

  // Add both layers to the final data structure
  data.layers.push(layer1);
  data.layers.push(layer2);

  return data;
}

const CircularNetwork = ({visualData,rndData,rndNo}) => {
  let vdata=visualData.data
  const keys = Object.keys(vdata);
  const length = vdata[keys[0]].length;
  const result = Array.from({ length }, (_, index) => {
    return keys.reduce((obj, key) => {
      obj[key] = vdata[key][index];
      return obj;
    }, {});
  });
  // const l1=rndData[rndNo]?.setting?.layer1Column?rndData[rndNo]?.setting?.layer1Column:  visualData.spec.columns[0].column
  // const l2=rndData[rndNo]?.setting?.layer2Column?rndData[rndNo]?.setting?.layer2Column:  visualData.spec.columns[1].column
  // const colorColumn=rndData[rndNo]?.setting?.colorColumn?rndData[rndNo]?.setting?.colorColumn:  visualData.spec.columns[0].column
  // const sizeColumn=rndData[rndNo]?.setting?.sizeColumn?rndData[rndNo]?.setting?.sizeColumn:  ''
  // const data=convert_table_to_json_for_network(result, l1, l2,  colorColumn, sizeColumn);
  const [data, setData] = useState(null);
  const svgRef = useRef(null);
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [selectedNode, setSelectedNode] = useState(null);

  useEffect(() => {
    const l1 = rndData[rndNo]?.setting?.layer1Column || visualData.spec.columns[0].column;
    const l2 = rndData[rndNo]?.setting?.layer2Column || visualData.spec.columns[1].column;
    const colorColumn = rndData[rndNo]?.setting?.colorColumn || visualData.spec.columns[0].column;
    const sizeColumn = rndData[rndNo]?.setting?.sizeColumn || '';
    const newData = convert_table_to_json_for_network(result, l1, l2, colorColumn, sizeColumn);
    setData(newData);
  }, [rndData, rndNo, visualData]);

  // const data = {
  //   "layers": [
  //     {"name": "l1", "nodes": [
  //       {"id": "l1.A", "name":"A", "group": "SEED", "count": 20},
  //       {"id": "l1.B", "name":"B", "group": "SEED", "count": 10},
  //       {"id": "l1.C", "name":"C", "group": "EXIT", "count": 5}
  //     ]},
  //     {"name": "l2", "nodes": [
  //       {"id": "l2.Apple", "name":"Apple"},
  //       {"id": "l2.Banana", "name":"Banana"},
  //       {"id": "l2.B", "name":"B"}
  //     ]},
  //   ],
  //   "edges": [
  //     {"from": "l1.A", "to": "l2.Apple"},
  //     {"from": "l1.A", "to": "l2.B"},
  //     {"from": "l1.B", "to": "l2.B"},
  //     {"from": "l1.C", "to": "l2.Apple"}
  //   ]
  // };
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      if (!entries || !entries.length) return;

      const { width, height } = entries[0].contentRect;
      setDimensions({ width, height });
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!data || !svgRef.current || !dimensions.width || !dimensions.height) return;


    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove(); // Clear previous render

    const { width, height } = dimensions;
    const centerX = width / 2;
    const centerY = height / 2;

    svg.attr('width', width).attr('height', height);

    const g = svg.append('g')
      .attr('transform', `translate(${centerX},${centerY})`);

    const radius = Math.min(width, height) / 2 - 50;
    const layerRadius = d3.scaleLinear()
      .domain([0, data.layers.length - 1])
      .range([radius * 0.3, radius]);

    // Modern tech startup color theme
    const colorScale = d3.scaleOrdinal()
      .domain(['SEED', 'EXIT', 'DEFAULT'])
      .range(['#b881f3', '#cb91f5',   '#7e57c2', 
        '#29b6f6', 
        '#ff7043',  
        '#66bb6a',  
        '#ffca28', 
        '#ec407a',  
        '#00b0ff',  
        '#651fff', 
        '#ff3d00', 
        '#1de9b6','#dea1f7', '#f1b1f9', '#86e3ff', '#9af3ff', '#aeffff', '#c2ffff',
    '#8a4bd0', '#6e3ba6', '#522b7c', '#361b52', '#5ba6ca', '#447997', '#2d4c64']);

    // Size scale for nodes
    const sizeScale = d3.scaleLinear()
      .domain([0, d3.max(data.layers.flatMap(layer => layer.nodes.map(node => node.count || 0)))])
      .range([5, 20]);

    // Draw layer circles
    g.selectAll('.layer-circle')
      .data(data.layers)
      .enter().append('circle')
      .attr('class', 'layer-circle')
      .attr('r', (d, i) => layerRadius(i))
      .attr('fill', 'none')
      .attr('stroke', '#e0e0e0')
      .attr('stroke-width', 1);

    // Create nodes data
    const nodes = data.layers.flatMap((layer, layerIndex) =>
      layer.nodes.map((node, nodeIndex) => ({
        ...node,
        layerIndex,
        angle: (nodeIndex / layer.nodes.length) * 2 * Math.PI - Math.PI / 2
      }))
    );

    // Create a map for quick edge lookup
    const edgeMap = new Map(data.edges.flatMap(edge => [
      [edge.from, edge.to],
      [edge.to, edge.from]
    ]));

    // Function to check if a node is related to the selected node
    const isRelatedNode = (node) => {
      if (!selectedNode) return true;
      if (node.id === selectedNode.id) return true;
      return edgeMap.has(selectedNode.id) && edgeMap.get(selectedNode.id).includes(node.id);
    };

    // Function to check if an edge is related to the selected node
    const isRelatedEdge = (edge) => {
      if (!selectedNode) return true;
      return edge.from === selectedNode.id || edge.to === selectedNode.id;
    };

    // Draw edges
    const edges = g.selectAll('.link')
      .data(data.edges)
      .enter().append('line')
      .attr('class', 'link')
      .attr('x1', d => {
        const node = nodes.find(n => n.id === d.from);
        return layerRadius(node.layerIndex) * Math.cos(node.angle);
      })
      .attr('y1', d => {
        const node = nodes.find(n => n.id === d.from);
        return layerRadius(node.layerIndex) * Math.sin(node.angle);
      })
      .attr('x2', d => {
        const node = nodes.find(n => n.id === d.to);
        return layerRadius(node.layerIndex) * Math.cos(node.angle);
      })
      .attr('y2', d => {
        const node = nodes.find(n => n.id === d.to);
        return layerRadius(node.layerIndex) * Math.sin(node.angle);
      })
      .attr('stroke', '#999')
      .attr('stroke-opacity', 0.6);

    // Draw nodes
    const nodeElements = g.selectAll('.node')
      .data(nodes)
      .enter().append('circle')
      .attr('class', 'node')
      .attr('cx', d => layerRadius(d.layerIndex) * Math.cos(d.angle))
      .attr('cy', d => layerRadius(d.layerIndex) * Math.sin(d.angle))
      .attr('r', d => sizeScale(d.count || 1))
      .attr('fill', d => colorScale(d.group || 'DEFAULT'))
      .style('cursor', 'pointer')
      .on('click', (event, d) => {
        setSelectedNode(selectedNode && selectedNode.id === d.id ? null : d);
      });

    // Add labels
    const labels = g.selectAll('.label')
      .data(nodes)
      .enter().append('text')
      .attr('class', 'label')
      .attr('x', d => layerRadius(d.layerIndex) * Math.cos(d.angle))
      .attr('y', d => layerRadius(d.layerIndex) * Math.sin(d.angle))
      .attr('dx', d => (sizeScale(d.count || 1) + 5) * Math.cos(d.angle))
      .attr('dy', d => (sizeScale(d.count || 1) + 5) * Math.sin(d.angle))
      .text(d => d.name)
      .attr('font-size', '12px')
      .attr('fill', '#333')
      .attr('text-anchor', d => Math.cos(d.angle) > 0 ? 'start' : 'end')
      .attr('dominant-baseline', d => Math.sin(d.angle) > 0 ? 'hanging' : 'baseline');

    // Add legend
    const legend = svg.append('g')
      .attr('transform', `translate(${width - 100}, 20)`);

    const legendData = Array.from(new Set(nodes.map(n => n.group))).filter(Boolean);

    legend.selectAll('.legend-item')
      .data(legendData)
      .enter().append('g')
      .attr('class', 'legend-item')
      .attr('transform', (d, i) => `translate(0, ${i * 20})`)
      .call(g => {
        g.append('rect')
          .attr('width', 15)
          .attr('height', 15)
          .attr('fill', d => colorScale(d));
        g.append('text')
          .attr('x', 20)
          .attr('y', 12)
          .text(d => d)
          .attr('font-size', '12px')
          .attr('fill', '#333');
      });

    // Update function to handle node selection
    const updateSelection = () => {
      nodeElements.attr('fill', d => isRelatedNode(d) ? colorScale(d.group || 'DEFAULT') : '#ccc');
      edges.attr('stroke', d => isRelatedEdge(d) ? '#999' : '#eee')
           .attr('stroke-opacity', d => isRelatedEdge(d) ? 0.6 : 0.2);
      labels.attr('fill', d => isRelatedNode(d) ? '#333' : '#999');
    };

    // Initial update
    updateSelection();

    // Effect cleanup
    return () => {
      nodeElements.on('click', null);
    };
  }, [data, selectedNode, dimensions]);

  return (
    <div ref={containerRef} className="w-full h-full">
      <svg ref={svgRef} className="w-full h-full"></svg>
    </div>
  );
};

export default CircularNetwork;
