import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useAxios } from "./hooks/useAxios";
import "./components/custom-react-toastify.css";
import "./i18n/i18n";

import MyDoc from "./pages/MyDoc";
import Layout from "./layout/index";
import SmartDoc from "./pages/SmartDoc";
import SignUp from "./pages/auth/SignUp";
import SignIn from "./pages/auth/SignIn";
import ResetPassword from "./pages/auth/ResetPassword";
import PasswordRecovery from "./pages/auth/PasswordRecovery";
import Pricing from "./pages/Pricing";
import Graph from "./pages/Graph";
import AuthComponent from "./hooks/AuthComponent";
import StripeSuccess from "./pages/stripe/Success";
import StripeCancel from "./pages/stripe/Cancel";
import MyAccount from "./pages/MyAccount";
import ContactSales from "./pages/ContactSales";

console.log("Environment: ", process.env.NODE_ENV);

function App() {
  useAxios();
  return (
    <div
      className="w-full h-screen min-h-[780px] overflow-y-auto text-gray-800 dark:text-gray-300 font-[500]"
      id="scroll"
    >
      <ToastContainer position="top-center" autoClose={3000} closeOnClick />
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route index element={<Navigate to="/smartdoc" />} />
            <Route
              path="/smartdoc/:docId?"
              element={<AuthComponent Component={SmartDoc} />}
            />
            <Route
              path="/mydoc"
              element={<AuthComponent Component={MyDoc} />}
            />
            <Route
              path="/pricing"
              element={<AuthComponent Component={Pricing} />}
            />
            <Route path="/smartdoc/share/:token" element={<SmartDoc />} />
            <Route
              path="/graph"
              element={<AuthComponent Component={Graph} />}
            />
            <Route
              path="/contact-sales"
              element={<AuthComponent Component={ContactSales} />}
            />
            <Route
              path="/graph/:graphId?"
              element={<AuthComponent Component={Graph} />}
            />
            <Route
              path="/myaccount"
              element={<AuthComponent Component={MyAccount} />}
            />
          </Route>
          <Route path="/reset" element={<ResetPassword />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/stripe/success" element={<StripeSuccess />} />
          <Route path="/stripe/cancel" element={<StripeCancel />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/password-recovery" element={<PasswordRecovery />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
