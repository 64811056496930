import React, { useRef, useCallback, useEffect } from 'react';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  // Controls,
  // MiniMap,
  // Background,
  ConnectionLineType
} from 'reactflow';
import 'reactflow/dist/style.css';

import CustomNode from './CustomNode';
import CustomSubFlow from './CustomSubFlow';
import ConnectionLine from './ConnectionLine';

const COLORS = ['#1976D2', '#01BDD2', '#69F0AE', '#FDBC1F', '#51B960', '#304FFE', '#FE8668', '#BA68C8', '#ED6C02', '#42A5F5', '#30D6B0']
const defaultViewport = { x: 50, y: 40, zoom: 0.9 };
const initialNodes = [
  {
    id: '1',
    type: 'input',
    data: { label: 'Starting Point' },
    position: { x: 150, y: 155 },
  },
];

// const minimapStyle = {
//   height: 120,
// };
const nodeTypes = {
  customNode: CustomNode,
  CustomSubFlow: CustomSubFlow
};

const FlowDiagram = (props) => {

  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const onConnect = useCallback((params) => setEdges((eds) => addEdge({ ...params, animated: true }, eds)), [setEdges]);
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const { data = {}, setDetails, rndNo, config, setStyleSettingNo, setShowDrawer ,visualData} = props
  const { nodes: p_nodes, edges: p_edges, clusters } = data
  const direction=visualData.data.direction
  console.log({direction})
  useEffect(() => {
    if (p_edges && p_nodes) {
      const n_edges = p_edges.map((ed, i) => {
        return { id: `edge-${ed.gvid}-${i}`, source: ed.tail, target: ed.head, label: ed.label, animated: true }
      })
      const maxY = getMaxYvalue(p_nodes, clusters)

      const n_clusters = clusters.map((clu, no) => {
        return {
          id: `sub_${clu.name}_${no}`,
          data: {
            no, label: clu.label,
            style: {
              width: Math.abs(clu.bb[2] - clu.bb[0]),
              height: Math.abs(clu.bb[3] - clu.bb[1]),
              ...(clu.style && { ...clu.style })
            },
          },
          nodes: clu.node_ids,
          position: { x: clu.bb[0] + 20, y: maxY + 30 - clu.bb[3] },
          type: 'CustomSubFlow',
        }
      })

      const n_nodes = Object.keys(p_nodes).map((key, no) => {
        const color = parseInt(key[0]) ? COLORS[parseInt(key[0])] : COLORS[0]
        const parentNode = getParentId(key, n_clusters)
        // const parentNode = null
        return {
          id: key,
          data: {
            color, no,
            obj_key: key,
            label: p_nodes[key].label,
            fields: [...p_nodes[key].fields],
            style: { ...p_nodes[key].style, width: p_nodes[key].bbpos.width, height: p_nodes[key].bbpos.height },
            rndNo: rndNo,
            config: config,
            setDetails,
            setShowDrawer,
            setStyleSettingNo,
            direction
          },
          position: {
            x: parentNode
              ? p_nodes[key].bbpos.x - parentNode.position.x + (no * 100) // Add horizontal spacing
              : p_nodes[key].bbpos.x + (no * 100), // Add
            y: parentNode
              ? (maxY - p_nodes[key].bbpos.y) - parentNode.position.y
              : (maxY - p_nodes[key].bbpos.y)
          },
      
          type: 'customNode',
          ...(parentNode && { parentNode: parentNode.id }),
          extent: 'parent',
          style: {
            ...p_nodes[key].style
          }
        }
      })

      // console.log(n_clusters)
      // setNodes(n_nodes)
      setNodes(n_clusters.concat(n_nodes))
      setEdges(n_edges)
    }
  }, [p_nodes, p_edges, clusters, setEdges, setNodes, setDetails, rndNo, config])

  const getMaxYvalue = (nodes, clusters) => {
    let maxY = 0
    Object.keys(nodes).map((key) => {
      if (nodes[key].bbpos.y > maxY) {
        maxY = nodes[key].bbpos.y
      }
      return maxY
    })
    clusters.map((clu) => {
      if (clu.bb[3] > maxY) {
        maxY = clu.bb[3]
      }
      return null
    })
    return maxY
  }

  const getParentId = (node_id, clusters = []) => {
    let parent = null
    clusters.map((val, ind) => {
      if (val.nodes.indexOf(node_id) > -1) {
        parent = val
      }
      return parent
    })
    return parent
  }

  return (
    <div className='h-full w-full relative'>
      <ReactFlowProvider>
        <div className="reactflow-wrapper w-full h-full" ref={reactFlowWrapper}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onDragOver={onDragOver}
            onConnectStart={(e) => e.stopPropagation()}
            nodeTypes={nodeTypes}
            defaultViewport={defaultViewport}
            connectionLineComponent={ConnectionLine}
            connectionLineType={ConnectionLineType.SmoothStep}
          // nodesDraggable={false} //disable drag nodes
          // panOnDrag={false}
          // nodesConnectable={false} // disable connecting nodes
          // minZoom={1.2} // disable zooming
          // maxZoom={1.2} // disable zooming
          // fitView
          >
            {/* <Controls /> */}
            {/* <MiniMap style={minimapStyle} zoomable pannable /> */}
            {/* <Background color="#aaa" gap={16} /> */}
          </ReactFlow>
        </div>
      </ReactFlowProvider>

    </div>
  );
};


export default FlowDiagram;
