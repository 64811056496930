import React from 'react';
import { startOfMonth, endOfMonth, eachDayOfInterval, format, getDay } from 'date-fns';

const getData=(data)=>{
  const keys = Object.keys(data);
  const length = data[keys[0]].length;

  const processedData = Array.from({ length }, (_, index) => {
      const obj = {};
      keys.forEach(key => {
          obj[key] = data[key][index];
      });
      return obj;
  });
  return processedData;
}
const MonthCalendar = ({visualData}) => {

  let data = getData(visualData.data);
  let config = {...visualData.config}
  const currentDate = new Date(config.month);
  const startDate = startOfMonth(currentDate);
  const endDate = endOfMonth(currentDate);
  const days = eachDayOfInterval({ start: startDate, end: endDate });

  const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const getTasksForDate = (date) => {
    return data.filter(task => task.date === format(date, 'yyyy-MM-dd'));
  };

  const getUniqueTags = (tasks) => {
    const tags = tasks.map(task => task.tag).filter(Boolean);
    return [...new Set(tags)];
  };

  return (
    <div className="calendar">
      <h2 className="text-2xl font-bold mb-4">{config.title}</h2>
      <div className="grid grid-cols-7 gap-1">
        {weekdays.map(day => (
          <div key={day} className="text-center font-bold p-2">{day}</div>
        ))}
        {Array(getDay(startDate)).fill(null).map((_, index) => (
          <div key={`empty-${index}`} className="p-2"></div>
        ))}
        {days.map(day => {
          const tasksForDay = getTasksForDate(day);
          const uniqueTags = getUniqueTags(tasksForDay);

          return (
            <div key={day.toString()} className="border p-2 min-h-[100px]">
              <div className="font-bold">{format(day, 'd')}</div>
              <div className="flex gap-1 mt-1">
                {uniqueTags.map(tag => (
                  <div
                    key={tag}
                    className="w-3 h-3 rounded-full"
                    style={{ backgroundColor: tag }}
                  ></div>
                ))}
              </div>
              <div className="mt-2">
                {tasksForDay.map((task, index) => (
                  <div key={index} className="text-sm">{task.task}</div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MonthCalendar;