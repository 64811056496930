import React, { useState } from 'react';
import { MapContainer, TileLayer, CircleMarker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const SimpleCard = ({ title, content }) => (
    <div style={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        backgroundColor: 'white',
        border: '1px solid #ccc',
        borderRadius: '4px',
        padding: '10px',
        maxWidth: '200px',
        boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
        zIndex: 1000
    }}>
        <h3 style={{ margin: '0 0 10px 0' }}>{title}</h3>
        {content}
    </div>
);
export const data = [
    { City: "New York", Country: "USA", Latitude: 40.7128, Longitude: -74.0060, Population: 8419600, Internet_Access: 87, Median_Age: 36.2 },
    { City: "Tokyo", Country: "Japan", Latitude: 35.6895, Longitude: 139.6917, Population: 37400068, Internet_Access: 94, Median_Age: 48.4 },
    { City: "London", Country: "UK", Latitude: 51.5074, Longitude: -0.1278, Population: 8982000, Internet_Access: 96, Median_Age: 40.0 },
    { City: "São Paulo", Country: "Brazil", Latitude: -23.5505, Longitude: -46.6333, Population: 12300000, Internet_Access: 74, Median_Age: 32.5 },
    { City: "Lagos", Country: "Nigeria", Latitude: 6.5244, Longitude: 3.3792, Population: 14600000, Internet_Access: 43, Median_Age: 18.1 }
];

export const config = {
    coord: ['Latitude', 'Longitude'],
    sizeBy: 'Population',
    colorBy: 'Internet_Access',
    //palette: ['#ff0000', '#00ff00', '#0000ff'],
    showCols: ['City', 'Country', 'Population', 'Internet_Access'],
    shape: 'Circle',
};
const SmartMap = ({ }) => {
    const [selectedItem, setSelectedItem] = useState(null);

    const getColor = (value) => {
        if (!config.palette || config.palette.length === 0) {
            // More elegant default color scale
            return `hsl(${200 + value * 1.2}, 70%, ${50 + value * 0.3}%)`;
        }
        const index = Math.floor((value / 100) * (config.palette.length - 1));
        return config.palette[index];
    };

    const renderShape = (item,index) => {
        const lat = item[config.coord[0]];
        const lng = item[config.coord[1]];
        const size = Math.max(5, item[config.sizeBy] / 1000000); // Minimum size of 5
        const color = getColor(item[config.colorBy]);

        if (config.shape === 'Circle') {
            return (
                <CircleMarker
                    key={index}
                    center={[lat, lng]}
                    radius={size}
                    fillColor={color}
                    color={color}
                    weight={1}
                    opacity={0.8}
                    fillOpacity={0.6}
                    eventHandlers={{
                        click: () => setSelectedItem(item),
                    }}
                />
            );
        } else if (config.shape === 'Bar') {
            const width = Math.max(5, item[config.xaxis] / 1000000); // Minimum width of 5
            const height = Math.max(5, item[config.yaxis]); // Minimum height of 5
            return (
                <div
                    key={index}
                    style={{
                        position: 'absolute',
                        left: `${lng}%`,
                        bottom: `${lat}%`,
                        width: `${width}px`,
                        height: `${height}px`,
                        backgroundColor: color,
                        opacity: 0.8,
                        cursor: 'pointer',
                    }}
                    onClick={() => setSelectedItem(item)}
                />
            );
        }
    };

    return (
        <div style={{ position: 'relative', width: '100%', height: '500px' }}>
            <MapContainer center={[20, 0]} zoom={2} style={{ height: '100%', width: '100%' }}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {data.map((item, index) => renderShape(item,index))}
            </MapContainer>
            {selectedItem && (
                <SimpleCard
                    title={selectedItem[config.showCols[0]]}
                    content={
                        <div>
                            {config.showCols.slice(1).map((col) => (
                                <p key={col}>{`${col}: ${selectedItem[col]}`}</p>
                            ))}
                        </div>
                    }
                />
            )}
        </div>
    );
};

export default SmartMap;