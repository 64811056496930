import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DropdownMenu } from "@radix-ui/themes";
import { BackpackIcon, CaretDownIcon, PersonIcon } from "@radix-ui/react-icons";
import { toast } from "react-toastify";
import {
  ExploreIcon,
  DataIcon,
  GraphIcon,
  WorkFlowIcon,
  LogoutIcon,
  ProfileIcon,
  DesktopIcon,
  DiamondIcon,
} from "../components/Icons";
import { fetchLogout } from "../store/slices/authSlice";

function Sidebar({ showSidebar }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const path = useLocation().pathname;
  const isSmallText = useSelector((state) => state.global.isSmallText);

  const [curItem, setCurItem] = useState("");
  const userData = useSelector((state) => state.auth.userData);

  const signOut = () => {
    dispatch(fetchLogout()).then(() => {
      toast.success(`You've logged out successfully!`);
      navigate("/signin");
    });
  };

  const user = useSelector((state) => state.auth.userData);

  function getUsernameFromEmail(email = "") {
    // Split the email address at the "@" symbol
    const parts = email.split("@");

    // The username is the first part of the email address
    const username = parts[0];

    return username;
  }
  useEffect(() => {
    if (path) {
      if (path.indexOf("smartdoc") > -1) setCurItem(0);
      if (path.indexOf("datasets") > -1) setCurItem(2);
      if (path.indexOf("graph") > -1) setCurItem(1);
      if (path.indexOf("workflow") > -1) setCurItem(3);
    }
  }, [path]);

  const subItems = [
    {
      label: t("SmartDoc"),
      icon: ExploreIcon,
      onclick: () => navigate("/smartdoc"),
    },
    { label: t("Graph"), icon: GraphIcon, onclick: () => navigate("/graph") },
    { label: t("Datasets"), icon: DataIcon, onclick: () => {} },
    { label: t("Workflow"), icon: WorkFlowIcon, onclick: () => {} },
    { label: t("Log Out"), icon: LogoutIcon, onclick: signOut },
  ];

  const SubItem = ({ label, Icon, no, onclick }) => {
    const isComingSoon = label === "Datasets" || label === "Workflow";
    return (
      <div
        className={`${showSidebar ? "flex" : "hidden"} 
        ${curItem === no ? "bg-primary text-white" : ""} 
        ${
          isComingSoon
            ? "cursor-not-allowed"
            : "cursor-pointer group hover:bg-primary hover:text-white"
        } 
        my-3 py-2 px-2 sm:px-4 items-center rounded transition`}
        onClick={() => !isComingSoon && onclick()}
        title={isComingSoon ? "Coming soon" : ""}
      >
        <div
          className={`sm:mr-6 w-fit ml-[2px] ${
            isComingSoon ? "" : "group-hover:invert group-hover:brightness-0"
          }`}
        >
          <Icon color={curItem === no ? "white" : "#666"} />
        </div>
        <p
          className={`${showSidebar ? "hidden sm:block" : "hidden"} ${
            isSmallText ? "text-sm" : ""
          }`}
        >
          {label}
        </p>
      </div>
    );
  };

  return (
    <aside
      className={`${
        showSidebar ? "sm:w-[250px] w-16 py-4 px-2" : "w-0"
      } shadow top-0
        h-screen min-h-[780px] fixed border-r transition-all bg-white dark:bg-[#111113] z-[3] z-[8]`}
    >
      <div className="sm:h-[calc(100%-350px)] h-[calc(100%-200px)]">
        <DropdownMenu.Root>
          <DropdownMenu.Trigger className="outline-none">
            <div
              className={`${
                showSidebar
                  ? "px-0 sm:px-4 dark:hover:bg-gray-600 hover:bg-gray-50 "
                  : "px-1"
              } flex justify-between items-center text-sm cursor-pointer  dark:hover:text-white py-2 transition rounded`}
            >
              <div className="gap-2 items-center flex">
                <PersonIcon
                  className={`${
                    showSidebar ? "hidden sm:block" : "hidden"
                  } cursor-default w-6 h-4`}
                />
                <p
                  className={`${
                    showSidebar ? "hidden sm:block" : "hidden"
                  } cursor-default`}
                >
                  {getUsernameFromEmail(user.email)}
                </p>
              </div>
              <CaretDownIcon className="w-5 h-5 mb-[2px]" />
            </div>
          </DropdownMenu.Trigger>

          <DropdownMenu.Content className="sm:w-[228px] w-[190px] text-black">
            <div className="py-2 px-1">
              <p> {getUsernameFromEmail(user.email)}</p>
              <p className="text-sm text-[#626262]">
                {userData?.emails && userData.emails[0]}
              </p>
            </div>
            <hr />
            <div className="py-3 px-1 text-sm">
              <DropdownMenu.Item className="w-full bg-white hover:bg-[#eee] dark:bg-transparent dark:hover:bg-gray-600 my-1">
                <div
                  className="cursor-pointer flex gap-4 py-1 items-center text-black dark:text-white  w-full"
                  onClick={() => navigate("/myaccount")}
                >
                  <ProfileIcon className="w-8 h-6 dark:text-white " />
                  <div>{t("Profile")}</div>
                </div>
              </DropdownMenu.Item>
              <DropdownMenu.Item
                className="w-full bg-white hover:bg-[#eee] dark:bg-transparent dark:hover:bg-gray-600 my-1"
                style={{ height: "fit-content" }}
              >
                <div className="cursor-pointer flex gap-4 py-1 items-center text-black dark:text-white">
                  <DesktopIcon className="w-8 h-5 dark:text-white " />
                  <div>{t("Subscription")}</div>
                </div>
              </DropdownMenu.Item>
              <DropdownMenu.Item className="w-full bg-white hover:bg-[#eee] dark:bg-transparent dark:hover:bg-gray-600 my-1">
                <div
                  className="cursor-pointer flex gap-4 items-center text-black dark:text-white"
                  onClick={() => navigate("/mydoc")}
                >
                  <BackpackIcon className="w-8 h-4" />
                  <div>{t("My Smartdoc")}</div>
                </div>
              </DropdownMenu.Item>
            </div>
            <hr />
            <div className="pb-1 pt-3 px-1 text-sm">
              <button
                className="text-left w-full uppercase"
                onClick={() => signOut()}
              >
                {t("Log Out")}
              </button>
            </div>
          </DropdownMenu.Content>
        </DropdownMenu.Root>

        <div className="mt-12">
          {subItems.map((item, no) => (
            <SubItem
              label={item.label}
              Icon={item.icon}
              onclick={item.onclick}
              no={no}
              key={no}
            />
          ))}
        </div>
      </div>
      <div className="p-3">
        <div
          style={{ backgroundImage: 'url("/imgs/upgrade-bg.png")' }}
          className={`${
            showSidebar ? "sm:flex hidden" : "hidden"
          } justify-center flex-col text-white p-2 w-full aspect-square bg-center bg-contain bg-no-repeat`}
        >
          <div className="bg-white w-8 h-7 items-center justify-center flex rounded mx-auto">
            <DiamondIcon color="#0694A3" />
          </div>
          <p className="text-lg text-center mt-2">{t("Upgrade")}</p>
          <p className="text-sm text-center mt-2">
            {t("Get access to all features")}
          </p>
          <button
            onClick={() => navigate("/pricing")}
            className="text-sm w-[calc(100%-16px)] bg-white text-primary ml-2 p-2 rounded mt-4"
          >
            {t("See Plans")}
          </button>
        </div>
        <button
          className={`w-full justify-center flex ${
            showSidebar ? "sm:hidden" : ""
          }`}
        >
          <DiamondIcon color="#0694A3" />
        </button>
      </div>
    </aside>
  );
}

export default Sidebar;
