import React, { useCallback, useEffect, useRef } from 'react';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts';
import echartThemeTech from "./themes/echartThemeTech.json";
import echartTheme from "./themes/echartTheme.json";
import echartThemeDark from "./themes/echartThemeDark.json";

const Chart = ({ option, style, className, events, chartRef,rd }) => {
  const echartsReactRef = useRef(null);
  const chartContainerRef = useRef(null);
  const themes=rd?.style?.theme?rd?.style?.theme:''
  // echarts.registerTheme('myCustomTheme', echartThemeTech);
   // 根据主题选择合适的主题配置
   const getTheme = (theme) => {
    switch (theme) {
      case 'tech':
        return echartThemeTech;
      case 'dark':
        return echartThemeDark;
      case 'normal':
      default:
        return echartTheme;
    }
  };
  const updateContainLabel = useCallback(() => {
    if (echartsReactRef.current && echartsReactRef.current.getEchartsInstance) {
      const chartInstance = echartsReactRef.current.getEchartsInstance();
      const containerWidth = chartContainerRef.current.clientWidth;
      const containerHeight = chartContainerRef.current.clientHeight;

      // 判断图表是否小于500px
      const isSmallSize = containerWidth < 140 || containerHeight < 80;

      // 获取图表配置项
      const { xAxis, yAxis, legend, tooltip, dataZoom, series } = option;

      const labelFontSize = 10;
      // 更新图表的配置
      chartInstance.setOption({
        // ...option,
        grid: {
          left: isSmallSize ? -18 : 0,
          right: isSmallSize ? 0 : 0,
          top: isSmallSize ? 0 : (option.legend && option.legend.show?'35px':'8px'),
          bottom: isSmallSize ? -18 : '0',
          containLabel: true,
          ...option.grid,
        },
        xAxis: {
          ...xAxis,
          show: !isSmallSize && xAxis, // 只在非小尺寸时显示横坐标轴
          axisLabel: {
            ...(xAxis && xAxis.axisLabel ? xAxis.axisLabel : {}),
            fontSize: labelFontSize,
          },
        },
        yAxis: {
          ...yAxis,
          show: !isSmallSize && yAxis, // 只在非小尺寸时显示纵坐标轴
          axisLabel: {
            ...(yAxis && yAxis.axisLabel ? yAxis.axisLabel : {}),
            fontSize: labelFontSize,
          },
        },
        legend: {
          ...legend,
          show: !isSmallSize && legend, // 只在非小尺寸时显示图例
          textStyle: {
            fontSize: labelFontSize,
          },
        },
        tooltip: {
          ...tooltip,
          show: !isSmallSize && tooltip, // 只在非小尺寸时显示提示框
        },
        dataZoom: {
          ...dataZoom,
          show: !isSmallSize && dataZoom !== undefined, // 只在非小尺寸时显示数据缩放条
        },
        series: series, // 保留基本图表
      });
    }
  }, [option])

  useEffect(() => {
    if (chartRef) {
      chartRef.current = echartsReactRef.current;
    }
    updateContainLabel();
    const resizeObserver = new ResizeObserver(() => {
      updateContainLabel();
    });
    resizeObserver.observe(chartContainerRef.current);
    return () => {
      resizeObserver.disconnect();
    };

  }, [chartRef, updateContainLabel]);
  
  const optionWithGrid = {
    ...option,
    legend:{
      type: 'scroll',
      top: 0,
    },
    barMaxWidth:'30'
  };
  const selectedTheme = getTheme(themes);
  return (
    <div ref={chartContainerRef}   style={{ width: '100%', height: '100%' }}>
      <ReactEcharts
        // key={setSettingStyleNo} 
        ref={echartsReactRef}
        option={optionWithGrid}
        style={{ width: '100%', height: '100%' ,...style}}
        className={className}
        theme={selectedTheme}
        onEvents={events}
      />
    </div>
  );
};

export default Chart;
