import {
  StarsIcon,
  H1Icon,
  H2Icon,
  TextIcon,
  GraphIcon,
  OrgIcon,
  MindmapIcon,
} from "../Icons";

import {
  ImageIcon,
  SketchLogoIcon,
  StackIcon,
  TableIcon,
  ViewGridIcon,
} from "@radix-ui/react-icons";

export const VISUALIZES = [
  //     {
  //     Icon: (color) => <TableIcon color={color} width={15} height={12} />,
  //     command: "/table",
  //     text: "Table",
  // },
  {
    Icon: (color) => <ImageIcon color={color} width={15} height={12} />,
    command: "/image",
    text: "Image",
  },
];

export const AI = [
  {
    Icon: (color) => <StarsIcon color={color} width={16} height={16} />,
    command: "/ai",
    text: "Ask to do something",
  },
  {
    Icon: (color) => <StarsIcon color={color} width={16} height={16} />,
    command: "/graphFromText",
    text: "Graph from text",
  },
  {
    Icon: (color) => <StarsIcon color={color} width={16} height={16} />,
    command: "/docAi",
    text: "AI Doc",
  },
  {
    Icon: (color) => <StarsIcon color={color} width={16} height={16} />,
    command: "/Website",
    text: "Website",
  },
  {
    Icon: (color) => <StarsIcon color={color} width={16} height={16} />,
    command: "/keyword",
    text: "Keyword",
  },
  {
    Icon: (color) => <StarsIcon color={color} width={16} height={16} />,
    command: "/LandingPage",
    text: "LandingPage",
  },
  {
    Icon: (color) => <StarsIcon color={color} width={16} height={16} />,
    command: "/Links",
    text: "Links",
  },
  {
    Icon: (color) => <StarsIcon color={color} width={16} height={16} />,
    command: "/card",
    text: "Card",
  },
  {
    Icon: (color) => <StarsIcon color={color} width={16} height={16} />,
    command: "/Extract",
    text: "Extract",
  },
  {
    Icon: (color) => <StarsIcon color={color} width={16} height={16} />,
    command: "/timeline",
    text: "Timeline",
  },
  {
    Icon: (color) => <StarsIcon color={color} width={16} height={16} />,
    command: "/testimonial",
    text: "Testimonial",
  },
  {
    Icon: (color) => <StarsIcon color={color} width={16} height={16} />,
    command: "/gallery",
    text: "Gallery",
  },
  {
    Icon: (color) => <StarsIcon color={color} width={16} height={16} />,
    command: "/websearch",
    text: "Web Search",
  },

  {
    Icon: (color) => <StarsIcon color={color} width={16} height={16} />,
    command: "/tree",
    text: "Tree",
  },
  {
    Icon: (color) => <GraphIcon color={color} width={15} height={12} />,
    command: "/flowchart",
    text: "Flow Chart",
  },
  {
    Icon: (color) => <OrgIcon color={color} width={16} height={13} />,
    command: "/orgchart",
    text: "Org Chart",
  },
  {
    Icon: (color) => <MindmapIcon color={color} width={17} height={12} />,
    command: "/mindmap",
    text: "Mindmap",
  },
  {
    Icon: (color) => <SketchLogoIcon color={color} width={15} height={12} />,
    command: "/network",
    text: "Network",
  },
  {
    Icon: (color) => <ViewGridIcon color={color} width={15} height={12} />,
    command: "/recordcard",
    text: "Record Card",
  },
  {
    Icon: (color) => <StackIcon color={color} width={16} height={15} />,
    command: "/accordion",
    text: "Accordion",
  },

  {
    Icon: (color) => <StarsIcon color={color} width={16} height={16} />,
    command: "/bar",
    text: "Bar",
  },
  {
    Icon: (color) => <StarsIcon color={color} width={16} height={16} />,
    command: "/donut",
    text: "Donut",
  },
  {
    Icon: (color) => <StarsIcon color={color} width={16} height={16} />,
    command: "/line",
    text: "Line",
  },
  {
    Icon: (color) => <StarsIcon color={color} width={16} height={16} />,
    command: "/style",
    text: "Style",
  },
  {
    Icon: (color) => <StarsIcon color={color} width={16} height={16} />,
    command: "/treemap",
    text: "Treemap",
  },
  {
    Icon: (color) => <StarsIcon color={color} width={16} height={16} />,
    command: "/heatmap",
    text: "Heatmap",
  },
  {
    Icon: (color) => <StarsIcon color={color} width={16} height={16} />,
    command: "/stackbar",
    text: "Stack Bar",
  },
  {
    Icon: (color) => <StarsIcon color={color} width={16} height={16} />,
    command: "/radar",
    text: "Radar",
  },
  {
    Icon: (color) => <StarsIcon color={color} width={16} height={16} />,
    command: "/textcloud",
    text: "Text Cloud",
  },
  {
    Icon: (color) => <StarsIcon color={color} width={16} height={16} />,
    command: "/table",
    text: "Table",
  },
  {
    Icon: (color) => <StarsIcon color={color} width={16} height={16} />,
    command: "/TaskCalendar",
    text: "TaskCalendar",
  },
];

export const TEXTS = [
  {
    Icon: (color) => <H1Icon color={color} width={16} height={16} />,
    command: "/h1",
    text: "Header 1",
  },
  {
    Icon: (color) => <H2Icon color={color} width={16} height={16} />,
    command: "/h2",
    text: "Header 2",
  },
  {
    Icon: (color) => <TextIcon color={color} width={16} height={16} />,
    command: "/text",
    text: "Text",
  },
  {
    Icon: (color) => <TextIcon color={color} width={16} height={16} />,
    command: "/heroline",
    text: "Heroline",
  },
  {
    Icon: (color) => <TextIcon color={color} width={16} height={16} />,
    command: "/poplink",
    text: "PopLink",
  },
  {
    Icon: (color) => <TextIcon color={color} width={16} height={16} />,
    command: "/markdown",
    text: "Markdown",
  },
];

export const ai_list = [
  "/landscape",
  "/ai",
  "/keyword",
  "/orgchart",
  "/flowchart",
  "/mindmap",
  "/network",
  "/recordcard",
  "/accordion",
  "/graphFromText",
  "/tree",
  "/docAi",
  "/Website",
  "/bar",
  "/donut",
  "/line",
  "/style",
  "/treemap",
  "/heatmap",
  "/stackbar",
  "/radar",
  "/textcloud",
  "/TaskCalendar",
  "/LandingPage",
  "/Links",
  "/card",
  "/Extract",
  "/timeline",
  "/testimonial",
  "/gallery",
  "/table",
  "/websearch",
];

export const TableColumns = [
  {
    field: "no",
    title: "No",
    headerEditor: "input-editor",
  },
  {
    field: "header-1",
    title: "Header-1",
    headerEditor: "input-editor",
  },
  {
    field: "header-2",
    title: "Header-2",
    headerEditor: "input-editor",
  },
];

export const banners = ["/topbanner", "/rightbanner", "/horizontal"];

export const textList = [
  "/h1",
  "/h2",
  "/text",
  "/heroline",
  "/poplink",
  "/markdown",
];
