import React, { useState, useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import ReactDOM from "react-dom";
import * as d3 from 'd3';
import { hexbin } from 'd3-hexbin';
import { Icon } from '@iconify/react';

//  const data = [
//     {
//       "status": "active",
//       "log": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//       "summary": "System running normally",
//       "icon": "mdi:check-circle",
//       "details": "All systems are operational and functioning within normal parameters."
//     },
//     {
//       "status": "warning",
//       "log": "Nullam non nisi est sit amet facilisis magna etiam.",
//       "summary": "Minor issues detected",
//       "icon": "mdi:alert",
//       "details": "Some non-critical issues have been detected. Monitoring closely."
//     },
//     {
//       "status": "error",
//       "log": "Morbi tincidunt augue interdum velit euismod in pellentesque.",
//       "summary": "Critical error occurred",
//       "icon": "mdi:alert-circle",
//       "details": "A critical error has been detected in the main processing unit."
//     },
//     {
//       "status": "inactive",
//       "log": "Sit amet cursus sit amet dictum sit amet justo.",
//       "summary": "System offline",
//       "icon": "mdi:power",
//       "details": "The system is currently offline for scheduled maintenance."
//     },
//     {
//       "status": "processing",
//       "log": "Egestas integer eget aliquet nibh praesent tristique magna sit.",
//       "summary": "Data processing",
//       "icon": "mdi:cog",
//       "details": "Large dataset is currently being processed. Estimated completion time: 2 hours."
//     },
//     {
//       "status": "completed",
//       "log": "Ac felis donec et odio pellentesque diam volutpat commodo sed.",
//       "summary": "Task completed",
//       "icon": "mdi:check",
//       "details": "The scheduled task has been completed successfully."
//     }
//   ];
  
//  const config = {
//     label: "status",
//     title: "summary",
//     body: "log",
//     icon: "icon",
//     colorBy: "status"
//   };
  const getData=(data)=>{
    const keys = Object.keys(data);
    const length = data[keys[0]].length;
  
    const processedData = Array.from({ length }, (_, index) => {
        const obj = {};
        keys.forEach(key => {
            obj[key] = data[key][index];
        });
        return obj;
    });
    return processedData;
  }
  const HexagonWall = ({visualData}) => {

    let data = getData(visualData.data);
    let config = {...visualData.config}

    const svgRef = useRef(null);
    const [selectedHexagon, setSelectedHexagon] = useState(null);
    const [error, setError] = useState(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  
    // 监听窗口尺寸变化
    useEffect(() => {
      const updateDimensions = () => {
        const container = svgRef.current.parentNode;
        setDimensions({
          width: container.clientWidth,
          height: container.clientHeight
        });
      };
  
      window.addEventListener('resize', updateDimensions);
      updateDimensions();  // 初始化时设置尺寸
  
      return () => {
        window.removeEventListener('resize', updateDimensions);
      };
    }, []);
  
    useEffect(() => {
      if (!data || !config || !dimensions.width || !dimensions.height) {
        return;
      }
  
      const svg = d3.select(svgRef.current);
      svg.attr('width', dimensions.width)
        .attr('height', dimensions.height);
      svg.selectAll('*').remove();
  
      const hexRadius = Math.min(
        Math.floor(0.94 * dimensions.width / data.length),
        Math.floor(0.1 * dimensions.width)
      );
  
      const hexbinGenerator = hexbin()
        .radius(hexRadius)
        .extent([[0, 0], [dimensions.width, dimensions.height]]);
  
      const points = data.map((_, i) => [
        (i % 6) * (hexRadius * 1.75),
        Math.floor(i / 6) * (hexRadius * 1.5) + hexRadius
      ]);
  
      const colorScale = d3.scaleOrdinal(d3.schemeCategory10);
  
      const hexagons = svg.selectAll('g')
        .data(hexbinGenerator(points))
        .enter().append('g')
        .attr('transform', d => `translate(${d.x},${d.y})`);
  
      hexagons.append('path')
        .attr('d', hexbinGenerator.hexagon())
        .attr('fill', (d, i) => colorScale(data[i][config.colorBy] || i))
        .attr('stroke', '#fff')
        .attr('stroke-width', 2)
        .style('filter', 'drop-shadow(0px 3px 3px rgba(0,0,0,0.2))');
  
      hexagons.append('text')
        .attr('dy', '-1.5em')
        .attr('text-anchor', 'middle')
        .attr('font-size', '14px')
        .attr('font-weight', 'bold')
        .text((d, i) => data[i][config.label]);
  
      hexagons.append('text')
        .attr('dy', '0em')
        .attr('text-anchor', 'middle')
        .attr('font-size', '12px')
        .text((d, i) => data[i][config.title]);
  
      hexagons.append('text')
        .attr('dy', '1.5em')
        .attr('text-anchor', 'middle')
        .attr('font-size', '10px')
        .text((d, i) => truncateText(data[i][config.body], 20));
  
      hexagons.each(function (d, i) {
        const iconName = data[i][config.icon];
        if (iconName) {
          d3.select(this).append('foreignObject')
            .attr('width', 24)
            .attr('height', 24)
            .attr('x', -12)
            .attr('y', 20)
            .append('xhtml:div')
            .style('display', 'flex')
            .style('justify-content', 'center')
            .style('align-items', 'center')
            .style('width', '100%')
            .style('height', '100%')
            .html(`<div id="icon-${i}"></div>`);
        }
      });
  
      hexagons.on('click', (event, d) => {
        const index = points.findIndex(p => p[0] === d.x && p[1] === d.y);
        setSelectedHexagon(data[index]);
      });
  
      data.forEach((item, i) => {
        const iconName = item[config.icon];
        if (iconName) {
          const iconElement = document.getElementById(`icon-${i}`);
          if (iconElement) {
            const root = createRoot(iconElement);
            root.render(<Icon icon={iconName} />);
          }
        }
      });
    }, [data, config, dimensions]);
  
    const truncateText = (text, maxLength) => {
      return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
    };
  
    if (error) {
      return <div>Error: {error}</div>;
    }
  
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <svg ref={svgRef}></svg>
        {selectedHexagon && (
          <div style={{ marginTop: '20px', padding: '10px', border: '1px solid #ccc' }}>
            <h3>Selected Hexagon Details:</h3>
            <pre>{JSON.stringify(selectedHexagon, null, 2)}</pre>
          </div>
        )}
      </div>
    );
  };
  

export default HexagonWall;